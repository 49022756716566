import React from 'react'
import { Link } from 'gatsby'
import { format } from 'react-string-format'

class Menu extends React.Component {
    render() {
        const menu = this.props.data

        const menuBlock = (
            <ul className="main-menu">
                {menu.elements.menu_items.value.map(item => (
                    <li className="main-menu__list-item main-menu__list-item--has-children" key={item.id}>
                        <Link
                           to={`${item.elements.content.value[0].elements.slug.value.startsWith('/') ? '' : '/'}${item.elements.content.value[0].elements.slug.value}`}
                           className="main-menu__list-item-link"
                           activeClassName="main-menu__list-item-link--active"
                           data-tracking-label={format('MainMenu_{0}', item.elements.title.value)}
                        >
                            {item.elements.title.value}
                        </Link>      
                        {item.elements.submenu_content.value.length > 0 ? (
                            <ul className="sub-menu">
                                {item.elements.submenu_content.value.map(submenuItem => (
                                    <li className="sub-menu__list-item" key={submenuItem.id}>
                                        <Link
                                            to={`${'/' + item.elements.content.value[0].elements.slug.value + '/'}${submenuItem.elements.slug.value}`}
                                            className="sub-menu__list-item-link"
                                            activeClassName="sub-menu__list-item-link--active"
                                            data-tracking-label={format('MainMenu_Sub_{0}', submenuItem.elements.title.value)}
                                        >
                                            {submenuItem.elements.title.value}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                         ) : ("")}
                    </li>
                ))}
            </ul>
        )

        return <nav className="menu">{menuBlock}</nav>
    }
}

export default Menu
